<template>
  <Modal
    :activeModal="activeModal"
    @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        v-if="permiteAcao($route, 'add')"
        :loading="loadingBtnCadastro" v-translate>Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        {{ isEdit ? `Editar ${getEntityType(3)}: ${item.cod} - ${item.name}` : `Cadastrar novo ${getEntityType(3)}` }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">

          <v-form>
            <div class="abas" v-show="isEdit" width="100%" v-if="false">
              <v-tabs
                v-model="tab"
                background-color="#FFF"
                height="30px">
                <!-- <v-tab href="#tab-1" class="abas-item">
                  Credenciador
                </v-tab> -->

                <!-- <v-tab href="#tab-2" class="abas-item" @click="requestComissao(item)">
                  Comissão
                </v-tab> -->
              </v-tabs>
            </div>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Dados
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <!-- <v-select
                    v-if="$store.getters['caixa/totalItemSelect'] < $store.getters.QuantidadeItensSelects"
                    :error-messages="parentIdErrors"
                    :items="listaItensSelect"
                    label="OSC"
                    v-model="item.parentId"
                    @input="$v.item.parentId.$touch()"
                    @blur="$v.item.parentId.$touch()" /> -->

                  <v-autocomplete
                    v-model="item.parentId"
                    :items="itemSelectEdit"
                    :label="getEntityType(2)"
                    @input="$v.item.parentId.$touch()"
                    @blur="$v.item.parentId.$touch()"
                    v-on:keyup="searchAutoComplete"
                    :search-input.sync="searchText"
                    :error-messages="institutionParentIdErrors"
                    :loading="isLoadingAutoComplete" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="institutionCodErrors" v-model="item.cod" label="COD:" type="number" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
                  <v-select
                    :items="interiorOptionList"
                    label="É interior?"
                    v-model="item.jsonFlags.isInterior"
                    :error-messages="isInteriorErrors"
                    @change="secureIsInterior('isInterior', item.jsonFlags.isInterior)"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
                  <v-select
                    :items="interiorOptionList"
                    label="Zerar saldo?"
                    v-model="item.jsonFlags.isResetBalance"
                    :error-messages="isResetBalance"
                    @change="secureIsInterior('isResetBalance', item.jsonFlags.isResetBalance)"/>
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="8">
                  <v-text-field :error-messages="institutionNameErrors" v-model="item.name" label="Nome:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="tel" :error-messages="institutionEmailErrors" v-model="item.email" label="Email:" @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="tel" :error-messages="institutionDocumentErrors" v-model="item.document" label="CPF/CNPJ:" @input="$v.item.document.$touch()" @blur="$v.item.document.$touch()" v-mask="['##.###.###/####-##', '###.###.###-##']"/>
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="tel" :error-messages="institutionPhoneErrors" v-model="item.phone" label="Telefone Comercial:" @input="$v.item.phone.$touch()" @blur="$v.item.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Endereço
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionCepErrors" v-model="item.cep" @keyup="buscarCep" label="CEP:" @input="$v.item.cep.$touch()" @blur="$v.item.cep.$touch()" v-mask="['#####-###']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionAddressErrors" v-model="item.address" label="Endereço:" @input="$v.item.address.$touch()" @blur="$v.item.address.$touch()" :disabled="cepValidate" /></v-col>
                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionCityErrors" v-model="item.city" label="Cidade:" @input="$v.item.city.$touch()" @blur="$v.item.city.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionStateErrors" v-model="item.state" label="Estado:" @input="$v.item.state.$touch()" @blur="$v.item.state.$touch()" /></v-col> -->

                <!-- componente de select de Estado e cidade -->
                <searchUfCity
                  @addUf="item.state = arguments[0]"
                  @addCity="item.city = arguments[0]"
                  :propUf="item.state"
                  :propCity="item.city"
                  :errorMessagesCity="institutionCityErrors"
                  :errorMessagesState="institutionStateErrors"
                  :propDisabled="cepValidate" />

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="institutionNeighborhoodErrors" v-model="item.neighborhood" label="Bairro:" @input="$v.item.neighborhood.$touch()" @blur="$v.item.neighborhood.$touch()" :disabled="cepValidate" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field type="tel" :error-messages="institutionNumberErrors" v-model="item.number" label="Número:" @input="$v.item.number.$touch()" @blur="$v.item.estado.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.complement" label="Complemento:" /></v-col>
              </v-row>
            </v-card-text>

            <!-- <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Dados do responsável da Credenciador:
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleNameErrors" v-model="item.responsible.name" label="Representante:" @input="$v.item.responsible.name.$touch()" @blur="$v.item.responsible.name.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCpfErrors" v-model="item.responsible.cpf" label="CPF:" @input="$v.item.responsible.cpf.$touch()" @blur="$v.item.responsible.cpf.$touch()" v-mask="['###.###.###-##']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsiblePhoneErrors" v-model="item.responsible.phone" label="Telefone:" @input="$v.item.responsible.phone.$touch()" @blur="$v.item.responsible.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleBusinessPhoneErrors" v-model="item.responsible.businessPhone" label="Telefone Comercial:" @input="$v.item.responsible.businessPhone.$touch()" @blur="$v.item.responsible.businessPhone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12">
                  <v-text-field :error-messages="responsibleEmailErrors" v-model="item.responsible.email" label="Email do Responsável:" @input="$v.item.responsible.email.$touch()" @blur="$v.item.responsible.email.$touch()"/>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text class="modal-cadastro-form">
              <v-card-title>
                Endereço do responsável do Credenciador:
              </v-card-title>
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCepErrors" v-model="item.responsible.cep"  @keyup="buscarCepResponsible" label="CEP:" @input="$v.item.responsible.cep.$touch()" @blur="$v.item.responsible.cep.$touch()" v-mask="['#####-###']"/></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleAddressErrors" v-model="item.responsible.address" label="Endereço:" @input="$v.item.responsible.address.$touch()" @blur="$v.item.responsible.address.$touch()" :disabled="cepValidateResponsible" /></v-col>
                <! -- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleCityErrors" v-model="item.responsible.city" label="Cidade:" @input="$v.item.responsible.city.$touch()" @blur="$v.item.responsible.city.$touch()" /></v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field :error-messages="responsibleStateErrors" v-model="item.responsible.state" label="Estado:" @input="$v.item.responsible.state.$touch()" @blur="$v.item.responsible.state.$touch()" /></v-col> -->
                <!-- componente de select de Estado e cidade -->
               <!--  <searchUfCity
                  @addUf="item.responsible.state = arguments[0]"
                  @addCity="item.responsible.city = arguments[0]"
                  :propUf="item.responsible.state"
                  :propCity="item.responsible.city"
                  :errorMessagesCity="responsibleCityErrors"
                  :errorMessagesState="responsibleStateErrors"
                  :propDisabled="cepValidateResponsible" />

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="responsibleNeighborhoodErrors" v-model="item.responsible.neighborhood" label="Bairro:" @input="$v.item.responsible.neighborhood.$touch()" @blur="$v.item.responsible.neighborhood.$touch()" :disabled="cepValidateResponsible" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="responsibleNumberErrors" v-model="item.responsible.number" label="Número:" @input="$v.item.responsible.number.$touch()" @blur="$v.item.responsible.number.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field v-model="item.responsible.complement" label="Complemento:" /></v-col>
              </v-row>
            </v-card-text> -->

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn"
                :loading="loading"
                v-if="permiteAcao($route, 'edit') || permiteAcao($route, 'add')">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item :key="2" :value="'tab-2'">
          <comission :entity="entity" v-on:close-modal="closeModal" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <ModalIsInterior @closeModalAvaliacao="closeModalAvaliacao"/>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength  } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import { formatPhone } from '@/core/service/utils'
import { isOnlyNumber, isValidCpfOrCnpj } from '@/utils/validationUtils'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
// import variables from '@/assets/styles/helpers/_variables.scss'
import axios from 'axios'
import config from '@/core/config'

import validacao from './validacao'
import searchUfCity from '@/views/components/searchUfCity'

/**
 * Mixins
 */
// import autoComplete from '@/views/cadastros/entities/mixins/autoComplete'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

// const validCNPJ = (params) => {
//   console.log(params)
// }

export default {
  name: 'ModalCadastroCredenciador',
  mixins: [validationMixin, modalCadastro],
  directives: {mask},
  components: {
    comission: () => import('./comission'),
    Modal: () => import('@/views/components/modal'),
    ModalIsInterior: () => import('./modalIsInterior'),
    searchUfCity,
  },

  data: () => ({
    interiorOptionList: [{ text: 'Sim', value: true }, { text:'Não', value: false }],
    activeModal: false,
    loading: false,
    tab: 'tab-1',
    entity: null,
    cepValidate: false,
    cepValidateResponsible: false
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('credenciador', {
      item: 'item',
      listaItensSelect: 'listaItensSelect'
    }),

    ...validacao
  },

  mounted () {
    // this.obterItensSelect()
    // Events.$on('cadastro::openModalCadastro', () => {
    //   this.activeModal = true
    // })
  },
  methods: {
    ...mapActions('credenciador', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect']),
    ...mapActions('credenciadorComission', {
      limparItemComission: 'limparItem'
    }),
    // openModal () {
    //   this.activeModal = true
    // },

    // closeModal () {
    //   this.activeModal = false
    //   this.limparItem()
    //   this.$v.item.$reset()
    // },

    closeModalAvaliacao ({ type, value }) {
      if(type === 'isResetBalance') {
        this.item.jsonFlags.isResetBalance = !value
      }

      if(type === 'isInterior') {
        this.item.jsonFlags.isInterior = !value
      }
    },

    closeModal () {
      this.activeModal = false
      this.tab = 'tab-1'
      this.entity = null
      this.limparItem()
      this.limparItemComission()
      this.$v.item.$reset()
      this.cepValidate = false,
      this.cepValidateResponsible = false
    },

    requestComissao (entity) {
      this.entity = entity
    },

    async buscarCep () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.cep)){
        const result = await axios.get(config.apiViaCep + this.item.cep + "/json/")
        if (result.data.erro) {
          this.cepValidate = false
          return
        }

        this.cepValidate = true
        this.item.address = result.data.logradouro
        this.item.state = result.data.uf
        this.item.city = result.data.localidade
        this.item.neighborhood = result.data.bairro
      }
    },

    async buscarCepResponsible () {
      if(/^[0-9]{5}-[0-9]{3}$/.test(this.item.responsible.cep)){
        const result = await axios.get(config.apiViaCep + this.item.responsible.cep + "/json/")
        if (result.data.erro) {
          this.cepValidateResponsible = false
          return
        }

        this.cepValidateResponsible = true
        this.item.responsible.address = result.data.logradouro
        this.item.responsible.state = result.data.uf
        this.item.responsible.city = result.data.localidade
        this.item.responsible.neighborhood = result.data.bairro
      }
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()
        document.querySelector('.v-dialog').scroll({ top: 0, left: 0, behavior: 'smooth' })
        return false
      }

      this.loading = true

      const dados = {
        parentId: this.item.parentId || null,
        jsonData: {
          cod: this.item.cod,
          name: this.item.name,
          email: this.item.email.toLowerCase(),
          document: this.item.document.replace(/\D/g, ''),
          address: this.item.address,
          cep: this.item.cep.replace(/\D/g, ''),
          phone: formatPhone(this.item.phone),
          city: this.item.city,
          state: this.item.state,
          neighborhood: this.item.neighborhood,
          number: this.item.number,
          complement: this.item.complement,
        },
        jsonFlags: {
          isInterior: this.item.jsonFlags.isInterior || false,
          isResetBalance: this.item.jsonFlags.isResetBalance || false
        }
      }

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then((result) => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.loading = false
          this.entity = {
            id: result.data.id,
            name: result.data.jsonData.name
          }
          // this.tab = 'tab-2'
          this.closeModal()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },
    secureIsInterior: (type, value) => {
      const data = { type, value }
      Events.$emit('cadastro::openModalIsInterior', data)
    }
    // secureIsResetBalance: () => Events.$emit('cadastro::openModalIsResetBalance')
  },

  validations () {
    return  {
      item: {
        parentId: { required },
        cod: { required, isOnlyNumber },
        name: { required },
        email: { required, email },
        document: { required, isValidCpfOrCnpj },
        address: { required },
        cep: { required, minLength: minLength(9) },
        phone: { required, minLength: minLength(14), maxLength: maxLength(15) },
        city: { required },
        state: { required },
        neighborhood: { required },
        number: { required },
        jsonFlags: {
          isInterior: { required },
          isResetBalance: { required }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>